import { useEffect, useRef } from "react";
import Button from "../../../components/button";
import SectionTitle from "../../../components/section-title";
import Parallax from "parallax-js";

const HomeAboutContainer = () => {
  const sceneEl = useRef(null);

  useEffect(() => {
    const parallaxInstance = new Parallax(sceneEl.current, {
      relativeInput: true,
    });

    parallaxInstance.enable();

    return () => parallaxInstance.disable();
  }, []);
  return (
    <div className="about-us position-relative" id="aboutsection">
      <div className="container">
        <div className="row mb-n7">
          <div className="col-xl-5 col-lg-6 mb-7">
            <div className="about-content">
              <SectionTitle
                classOption="title-section"
                subTitle="ABOUT US"
                title="Absolute     
                                <span class='text-primary'>
                                Job Advertising 
                                </span>
                                <br className='d-none d-xl-block' />
                                Solution"
              />

              <p className="mb-5">
                Our All in One job advertising solution cover all your needs at
                one place. Our state of the art programmatic tech ensures your
                money is spent well finding the best candidate for your job
                providing you with simple yet advanced anayltics facilitating
                you to make right decisions for your business. Recrutiment
                optimized Bot/Spam Protection makes sure your ads are in front
                of real candidates and help you identify the faulty traffic
                source.
              </p>
              <p className="high-light mb-8">
                Our Experts in recruitment advertising are 24/7 available to
                help you through the best solution for your business.
              </p>
              {/* <Button
                                classOption="btn btn-lg btn-dark btn-hover-dark"
                                text="Learn more"
                                path="/about"
                            /> */}
            </div>
          </div>
          <div className="col-xl-6 order-first order-lg-last col-lg-6 mb-7 offset-xl-1">
            <div
              className="about-photo scene text-center text-lg-left"
              id="scene"
              ref={sceneEl}
            >
              <div data-depth="0.2">
                <img
                  src={`${process.env.PUBLIC_URL}/images/about/1.png`}
                  alt="about"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        className="about-shape"
        src={`${process.env.PUBLIC_URL}/images/about/shape.png`}
        alt="bg-shape"
      />
    </div>
  );
};

export default HomeAboutContainer;
