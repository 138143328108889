import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../components/logo";
import SocialIcon from "../../components/social-icon";

const Footer = () => {
  return (
    <footer className="footer-section">
      <div className="footer-top position-relative">
        <img
          className="footer-shape"
          src={process.env.PUBLIC_URL + "/images/footer/1.png"}
          alt="shape"
        />
        <div className="container">
          <div className="row mb-n7">
            <div className="col-lg-6 col-sm-6 mb-7">
              <div className="footer-widget">
                <Logo
                  classOption="footer-logo mb-9"
                  image={`${process.env.PUBLIC_URL}/images/logo/thejobmatrix.png`}
                />
                <p>The JobMatrix Limited</p>
                <ul className="footer-social-links">
                  <li>
                    <SocialIcon
                      classOption="footer-social-link"
                      path="https://www.facebook.com/"
                      icon="icofont-facebook"
                    />
                  </li>
                  <li>
                    <SocialIcon
                      classOption="footer-social-link"
                      path="https://www.instagram.com/"
                      icon="icofont-instagram"
                    />
                  </li>
                  <li>
                    <SocialIcon
                      classOption="footer-social-link"
                      path="https://twitter.com/"
                      icon="icofont-twitter"
                    />
                  </li>
                  <li>
                    <SocialIcon
                      classOption="footer-social-link"
                      path="https://www.whatsapp.com/"
                      icon="icofont-whatsapp"
                    />
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="col-lg-3 col-sm-6 mb-7 offset-xl-1">
                            <div className="footer-widget">
                                <h4 className="title">Quick Link</h4>
                                <ul className="footer-menu">
                                    <li>
                                        <Link
                                            className="footer-link"
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/about"
                                            }
                                        >
                                            About us
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            className="footer-link"
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/service"
                                            }
                                        >
                                            Pricing Plan
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            className="footer-link"
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/about"
                                            }
                                        >
                                            Conditions
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            className="footer-link"
                                            to={
                                                process.env.PUBLIC_URL + "/blog"
                                            }
                                        >
                                            Blog Post
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            className="footer-link"
                                            to={
                                                process.env.PUBLIC_URL + "/team"
                                            }
                                        >
                                            Our Clients
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            className="footer-link"
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/contact"
                                            }
                                        >
                                            Contact us
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
            {/* <div className="col-lg-2 col-sm-6 mb-7">
                            <div className="footer-widget">
                                <h4 className="title">Services</h4>
                                <ul className="footer-menu">
                                    <li>
                                        <Link
                                            className="footer-link"
                                            to={
                                                process.env.PUBLIC_URL +
                                                `/service-details/2`
                                            }
                                        >
                                            Business Plan
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            className="footer-link"
                                            to={
                                                process.env.PUBLIC_URL +
                                                `/service-details/3`
                                            }
                                        >
                                            Creative Strategy
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            className="footer-link"
                                            to={
                                                process.env.PUBLIC_URL +
                                                `/service-details/4`
                                            }
                                        >
                                            Branding{" "}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            className="footer-link"
                                            to={
                                                process.env.PUBLIC_URL +
                                                `/service-details/1`
                                            }
                                        >
                                            Digital Solution
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            className="footer-link"
                                            to={
                                                process.env.PUBLIC_URL +
                                                `/service-details/5`
                                            }
                                        >
                                            Marketing Policy
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            className="footer-link"
                                            to={
                                                process.env.PUBLIC_URL +
                                                `/service-details/6`
                                            }
                                        >
                                            Campaign &amp; PR
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
            <div className="col-lg-6 col-sm-6 ">
              <div className="footer-widget">
                <h4 className="title">Contact Information</h4>
                {/* <span className="imggs">
                  <img
                    className="footerlocation"
                    src={`${process.env.PUBLIC_URL}/images/footer/location.svg`}
                    alt="link"
                  />
                </span>
                <span className="imggs">
                  <p>
                    International House, 307 Cotton Exchange, Old Hall Street
                    Liverpool, United Kingdom, L3 9LQ
                  </p>
                </span> */}
                <ul className="address">
                  <li>
                    <div className="d-flex justify-content-start  inffo">
                      <div className="info_icon ">
                        <img
                          className="footerlocation "
                          src={`${process.env.PUBLIC_URL}/images/footer/location.png`}
                          alt="link"
                        />
                      </div>
                      <div className="info_text_location  ">
                        <p>
                          International House, 307 Cotton Exchange, Old Hall
                          Street Liverpool, United Kingdom, L3 9LQ
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex justify-content-start  inffo">
                      <div className="info_icon ">
                        <img
                          className="footerphone"
                          src={`${process.env.PUBLIC_URL}/images/footer/phone.svg`}
                          alt="link"
                        />{" "}
                        &nbsp;{" "}
                      </div>
                      <div className="info_text">
                        <a className="address-link" href="tel:+98745612398">
                          +447868769749
                        </a>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex justify-content-start  inffo_email">
                      <div className="info_icon ">
                        <img
                          className="footeremail"
                          src={`${process.env.PUBLIC_URL}/images/footer/email.png`}
                          alt="link"
                        />{" "}
                        &nbsp;{" "}
                      </div>
                      <span className="info_text_phone">
                        <a
                          className="address-link"
                          href="mailto:info@example.com"
                        >
                          contact@thejobmatrix.com
                        </a>
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copy-right-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="copyright-info text-center">
                <p>
                  Copyright &copy; 2022 Made By
                  <a href="/" target="_blank" rel="noreferrer">
                    &nbsp; TheJobMatrix
                  </a>
                  , All Rights Reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
