import { NavLink } from "react-router-dom";
import { Link } from "react-scroll";

const MainMenu = () => {
  return (
    <nav className="main-menu d-none d-lg-block">
      <ul className="d-flex">
        <li>
          <NavLink exact to="/">
            Home
          </NavLink>
        </li>
        <li>
          <Link to="aboutsection" offset={-100}>
            <p>About</p>
          </Link>
        </li>
        <li>
          <Link to="service">
            <p>Services</p>
          </Link>
        </li>

        <li>
          <Link to="contact">
            <p>Contact</p>
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default MainMenu;
