import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import HomeData from "../../../data/home.json";
import Brand from "../../../components/custom_brand/index";

const CustomBrandContainer = () => {
  const swiperOption = {
    loop: true,
    speed: 800,
    autoplay: {
      delay: 2000,
    },
    slidesPerView: 4,
    spaceBetween: 0,
    pagination: false,
    navigation: true,
    // Responsive breakpoints
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1,
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 2,
      },
      // when window width is >= 640px
      768: {
        slidesPerView: 3,
      },

      // when window width is >= 640px
      992: {
        slidesPerView: 4,
      },
    },
  };
  return (
    <div className="brand-section spacer">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2 className="brand-heading">
              <strong>
                Traffic<span className="text-primary"> Acquisition</span>{" "}
                Partners
              </strong>
            </h2>
            <Swiper className="brand-carousel" {...swiperOption}>
              {HomeData[2].custombrand &&
                HomeData[2].custombrand.map((single, key) => {
                  return (
                    <SwiperSlide key={key}>
                      <Brand key={key} data={single} />
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomBrandContainer;
