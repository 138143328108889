import React from "react";
import { Link } from "react-router-dom";

function MessageSuccess() {
  return (
    <div>
      <div className="container">
        <div className="row">
          <div
            className="col-12 col-sm-8 col-md-6 col-lg-4"
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "130px",
            }}
          >
            <div className="card border-0">
              <div className="card-body">
                <div className="w4rAnimated_checkmark"></div>
                <img
                  src={
                    process.env.PUBLIC_URL + "./images/contact/check-mark.png"
                  }
                  alt="brand logo"
                  width="200px"
                  height="200px"
                  style={{ marginLeft: "60px" }}
                />
                <h5>Thank You!</h5>

                <p className="card-text">Your Application Has Been Received</p>
                <Link to="/" style={{ marginLeft: "60px" }}>
                  Back to previous page
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MessageSuccess;
