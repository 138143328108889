import PropTypes from "prop-types";

const CustomBrand = ({ data }) => {
    return (
        <div className="single-brand">
            <img src={process.env.PUBLIC_URL + data.image} alt="brand logo" className="branded-image image-gray"/>
        </div>
    );
};

CustomBrand.propTypes = {
    data: PropTypes.object,
};

export default CustomBrand;
