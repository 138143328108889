import React, { Fragment, useState } from "react";
import { Redirect } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";

import ScaleLoader from "react-spinners/ScaleLoader";
import { useHistory } from "react-router-dom";

const ContactForm = () => {
  const history = useHistory();
  const { register, errors } = useForm({
    mode: "onBlur",
  });

  const [first_name, setFirstName] = useState("sss");
  const [last_name, setLastName] = useState("");
  const [designation, setDesignation] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [company, setCompany] = useState("");
  const [flash, setFlash] = useState(false);
  const [loader, setLoader] = useState(false);
  const [nav, setNav] = useState(false);

  const handleContactData = async (event) => {
    event.preventDefault();

    setFlash(false);
    setLoader(true);

    const former = new FormData();
    former.append("fname", first_name);
    former.append("lname", last_name);
    former.append("designation", designation);
    former.append("company", company);
    former.append("email", email);
    former.append("subject", subject);
    former.append("message", message);

    await axios
      // .post("http://127.0.0.1:8000/api/talk_jobmatrix/", former)
      .post("https://dev.thejobnexus.com/api/talk_jobmatrix/", former)

      .then(function (response) {
        event.target.reset();
        setFlash(true);
        setNav(true);
        setLoader(false);
        // history.push("/success");
      })
      .catch(function (response) {
        console.log(response);
      });
  };

  return (
    <>
      {nav && <Redirect to="/success" />}
      <Fragment>
        <form
          id="contactForm"
          className="row"
          onSubmit={handleContactData}
          method="POST"
          encType="multipart/form-date"
        >
          <div className="col-12 col-sm-6 mb-7">
            <input
              required
              type="text"
              className="form-control"
              id="fname"
              name="fname"
              placeholder="First Name*"
              ref={register({ required: "First Name is required" })}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
            />
            {errors.name && <p>{errors.name.message}</p>}
          </div>

          <div className="col-12 col-sm-6 mb-7">
            <input
              required
              type="text"
              className="form-control"
              id="lname"
              name="lname"
              placeholder="Last Name*"
              ref={register({ required: "Last Name is required" })}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
            />
            {errors.name && <p>{errors.name.message}</p>}
          </div>

          <div className="col-12 col-sm-6 mb-7">
            <input
              required
              type="text"
              className="form-control"
              id="designation"
              name="designation"
              placeholder="Your Designation*"
              ref={register({ required: "Designation is required" })}
              onChange={(e) => {
                setDesignation(e.target.value);
              }}
            />
            {errors.designation && <p>{errors.designation.message}</p>}
          </div>
          <div className="col-12 col-sm-6 mb-7">
            <input
              required
              type="text"
              className="form-control"
              id="company"
              name="company"
              placeholder="Company Name*"
              ref={register({
                required: "Company is required",
                pattern: {
                  message: "Company Name Is Required",
                },
              })}
              onChange={(e) => {
                setCompany(e.target.value);
              }}
            />
            {errors.company && <p>{errors.company.message}</p>}
          </div>

          <div className="col-12 col-sm-6 col-lg-12 mb-7">
            <input
              required
              type="email"
              className="form-control"
              id="email"
              name="email"
              placeholder="Your Email*"
              ref={register({
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Email Required",
                },
              })}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            {errors.email && <p>{errors.email.message}</p>}
          </div>

          <div className="col-12 col-sm-6 col-lg-12 mb-7">
            <input
              required
              type="text"
              className="form-control"
              id="subject"
              name="subject"
              placeholder="Subject*"
              ref={register({
                required: "Subject is required",
                pattern: {
                  message: "Subject Required",
                },
              })}
              onChange={(e) => {
                setSubject(e.target.value);
              }}
            />
            {errors.subject && <p>{errors.subject.message}</p>}
          </div>

          <div className="col-12 mb-9">
            <textarea
              required
              className="form-control massage-control"
              name="message"
              id="massage"
              cols="30"
              rows="10"
              placeholder="Message"
              ref={register({
                required: "Message is required",
              })}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            ></textarea>
            {errors.message && <p>{errors.message.message}</p>}
          </div>
          <div className="col-6">
            <button
              id="contactSubmit"
              type="submit"
              className="btn btn-dark btn-hover-dark"
              data-complete-text="Well Done!"
            >
              Send Message
            </button>
          </div>

          <div className="col-6">
            {loader && <ScaleLoader color="#00DF98" className="scale-loader" />}
          </div>
        </form>
      </Fragment>
    </>
  );
};

export default ContactForm;
